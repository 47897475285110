import { useState, useEffect } from 'react'
import { useIsLoggedIn } from './useIsLoggedIn';
import { navigate } from "gatsby"


export const useRedirectWhenLoggedIn = () => {

    const [ authOnceChecked, setAuthOnceChecked ] = useState( false );
    const isLoggedIn = useIsLoggedIn()

    useEffect( () => {
        if ( isLoggedIn === true || isLoggedIn === false ) {
            setAuthOnceChecked( true )
        }
        isLoggedIn && !authOnceChecked && navigate( "/home" )
    }, [ isLoggedIn ] )
}
