import React, { useState, useContext, FC } from "react"
import Styles from "../styles/ModalTemplate.module.scss";
import GeneralButton from "../components/atoms/GeneralButton";
import Modal from "../components/Modal";
import InputForm from "../components/atoms/InputForm";
import { ToastContext } from '../context/ToastContext';
import client from "../apis/client";


type Props = {
    url: string
    onSucceeded: () => void
    onCancel: () => void
    initialEmail: string
}

const PasswordResetTemplate: FC<Props> = ( {
    url,
    onSucceeded,
    onCancel,
    initialEmail = ""
} ) => {

    const [ email, setEmail ] = useState( initialEmail )
    const [ emailErrorMessage, setEmailErrorMessage ] = useState( "" )
    const [ submitting, setSubmitting ] = useState( false )
    const { addToast } = useContext( ToastContext );


    const submit = async () => {
        if ( !email ) {
            alert( '入力されていない欄があります' )
            return
        }
        if ( submitting ) return

        const data = new FormData()
        data.append( "email", email )
        const onSucceededFunction = onSucceeded
        setSubmitting( true )
        await client.post(url, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            withCredentials: true,
        } )
            .then( ( res ) => {
                setSubmitting( false )
                addToast( {
                    text: `${ initialEmail ? "新しいパスワード" : "パスワード再設定用メール" }を、当該アドレスに送信しました`,
                    type: "success",
                    duration: 10000,
                    position: 'center'
                } )
                // alert( `${ initialEmail ? "新しいパスワード" : "パスワード再設定用メール" }を、当該アドレスに送信しました` )
                alert( 'アドレスが間違っていた場合はメールは届きません' )
                alert( 'メールアドレスが正しいかどうかはセキュリティ上お伝えできません' )
                onSucceededFunction()
            } )
            .catch( error => {
                // onSucceededFunction()
                let data = error.response.data
                setEmailErrorMessage( data.email )
                setSubmitting( false )
            } )
    }


    return (
        <Modal visible={ true } closeModal={ () => { } } closeModalIconVisible={ false }>
            <>
                <p className={ Styles.heading }>
                    {
                        initialEmail ?
                            "パスワードを再発行し、新しいパスワードを以下のチームのメールアドレスにお送りします" :
                            "パスワード再設定用のメールをお送りします"
                    }
                </p>
                { initialEmail &&
                    <p className={ Styles.detail }>メールアドレス:{ email }</p> }
                { initialEmail ||
                    <InputForm
                        label="登録してあるメールアドレス"
                        name="email"
                        placeholder=""
                        type="text"
                        value={ email }
                        labelStyle={ { color: "#fff" } }
                        errorMessage={ emailErrorMessage }
                        onChange={ ( name: string, value: string ) => setEmail( value ) }
                    /> }
                <div className={ Styles.button_container }>
                    <GeneralButton title="キャンセル" onClick={ () => onCancel() } transparent/>
                    <GeneralButton title="再発行" loading={ submitting } onClick={ () => submit() } />
                </div>
            </>
        </Modal>
    );
}

export default PasswordResetTemplate
