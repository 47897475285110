import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import Styles from "../styles/Login.module.scss";
import SEO from "../components/seo"
import Image from "../components/image"
import MediaQuery from "react-responsive";
import InputForm from "../components/atoms/InputForm";
import GeneralButton from "../components/atoms/GeneralButton";
import PasswordResetTemplate from "../templates/PasswordResetTemplate"
import amplitude, { getUserPropertyForAmplitude } from "../Analytics";
import ffmpeg from "../utils/FFmpeg"
import { useDispatch } from "react-redux";
import { login } from "../apis/user"
import { useRedirectWhenLoggedIn } from "../hooks/useRedirectWhenLoggedIn";

const LoginPage = () => {
    const [ email, setEmail ] = useState( "" )
    const [ password, setPassword ] = useState( "" )
    const [ uploading, setUploading ] = useState( false )
    const [ errorMessage, setErrorMessage ] = useState( "" )
    const [ passwordResetModalVisible, setPasswordResetModalVisible ] = useState( false )
    const dispatch = useDispatch();
    useRedirectWhenLoggedIn()

    const submit = async () => {
        setUploading( true )
        try {
            const { data } = await login( {
                email,
                password
            } )
            localStorage.setItem( "userID", data.id )
            getUserPropertyForAmplitude( amplitude )
            setUploading( false )
            ffmpeg.init()
            dispatch( { type: "setIsLoggedIn", isLoggedIn: true } )
            dispatch( { type: "setUserUuid", userUuid: data.id } )
            dispatch( { type: "setUserName", userName: data.name } )
            dispatch( { type: "setTeamData", teamData: data.team_data } )
            if ( !data.has_completed_registration ) {
                navigate( "/welcome" )
                return
            }
            const callbackURL = new URLSearchParams( window.location.search ).get( "callback" )
            callbackURL ?
                navigate( callbackURL ) :
                navigate( "/home" )
        }
        catch ( error ) {
            console.log( error )
            setUploading( false )
            if ( error.response.status == 400 ) {
                setErrorMessage( "アドレスとパスワードのいずれか、もしくは両方が間違っています" )
                return
            }
            if ( 500 <= error.response.status ) {
                setErrorMessage( "サーバーエラーが起きました。\nお手数ですが、時間をおいてお試しください" )
            }
            else {
                setErrorMessage( "なんらかのエラーが発生しました。\n通信状況などをご確認の上、再度お試しください。" )
            }
        }
    }

    const onChange = ( name: string, value: string ) =>
        name === "email" ?
            setEmail( value ) :
            setPassword( value )

    return (
        <div className={ Styles.container_wrapper }>
            <SEO title="Login" />
            <div className={ Styles.container }>
                <div className={ Styles.content }>
                    <div className={ Styles.input_wrapper }>
                        <InputForm
                            onChange={ onChange }
                            label="メールアドレス"
                            name="email"
                            placeholder="sample@mail.com"
                            value={ email }
                            type="text"
                            labelStyle={ { fontWeight: 'normal', } }
                            inputFormStyle={ { borderRadius: 4, padding: 8 } }
                            errorMessage="" />
                    </div>
                    <div className={ Styles.input_wrapper }>
                        <InputForm
                            onChange={ onChange }
                            label="パスワード"
                            name="password"
                            placeholder="8文字以上です。大文字は入っていない可能性があります"
                            value={ password }
                            type="password"
                            labelStyle={ { fontWeight: 'normal', } }
                            inputFormStyle={ { borderRadius: 4, padding: 8 } }
                            errorMessage="" />
                    </div>
                    <p className={ Styles.error }>{ errorMessage }</p>
                    <GeneralButton
                        onClick={ submit }
                        title="ログイン"
                        loading={ uploading }
                        style={ { padding: "8px 24px", width: "100%", marginBottom: 32 } } />
                    <div style={ { display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center" } }>
                        <Link to="/registration" style={ { marginBottom: 24 } }>新規登録はこちら</Link>
                        <Link to="/" style={ { marginBottom: 48 } }>トップページ</Link>
                        <a
                            style={ { cursor: "pointer" } }
                            id="a"
                            href=""
                            onClick={ ( e ) => {
                                e.preventDefault();
                                setPasswordResetModalVisible( true )
                            } } >
                            パスワードをお忘れの方はこちら
                    </a>
                    </div>
                    { passwordResetModalVisible &&
                        <PasswordResetTemplate
                            url="/api/users/password/reset/"
                            initialEmail={ "" }
                            onSucceeded={ () => setPasswordResetModalVisible( false ) }
                            onCancel={ () => setPasswordResetModalVisible( false ) } /> }
                </div>
            </div>
            <MediaQuery query="(min-width: 900px)">
                <div className={ Styles.image_container }>
                    <Image filename="top_mock.png" style={ { width: "40vw", height: "70vh" } } />
                </div>
            </MediaQuery>
        </div>
    )
}

export default LoginPage
